<template lang="pug">
confirmation-dialog(
  v-model="showing"
  title="Will not come to course"
  descr="By this action, you will free 1 place for reservation on this course"
  @okBtnClicked="update(false)"
  @failBtnClicked="toggle"
  @click:outside="toggle"
)
  template(v-slot:activator="{on}")
    v-menu(v-model="menuShowing" :close-on-click="false")
      template(v-slot:activator="{ on }")
        span(@click="show") {{ will_come_to_course ? 'Yes' : 'No' }}
      div.select-menu(v-if="menuShowing" v-click-outside="hide")
        div.select-menu-list
          v-list
            v-list-item(@click="update(true)") Yes
            v-list-item(@click="toggle") No
</template>

<script>
import vClickOutside from 'v-click-outside'
import showingMixin from '@/mixins/showing.mixin'

export default {
  directives: {
    clickOutside: vClickOutside.directive
  },

  mixins: [showingMixin],

  props: {
    will_come_to_course: {
      type: Boolean,
      default: false,
    }
  },

  data: () => ({
    menuShowing: false,
  }),

  methods: {
    update(val) {
      if (val === this.will_come_to_course) return
      this.$emit('update', val)
      this.menuShowing = false
    },

    show(val) {
      this.menuShowing = true
    },

    hide(val) {
      this.menuShowing = false
    },
  },

  components: {
    confirmationDialog: () => import('@/components/global/ConfirmationDialog.vue')
  }
}
</script>
